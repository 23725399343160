@import 'src/mobile-mode.scss'

@mixin mobilemd($mobilem)
  @if $desktop-mode == '2'
    @media only screen and (min-width: 480px)
      height: calc(425px / 7)
  @if $desktop-mode == '3'
    @media only screen and (min-width: 480px)
      height: calc(80vw / 7)

@mixin mobilemd100($mobilem)
  @if $desktop-mode == '2'
    @media only screen and (min-width: 480px)
      width: 425px
      margin-left: calc( (100% - 425px) / 2)
  @if $desktop-mode == '3'
    @media only screen and (min-width: 480px)
      width: 80vw
      margin-left: 10vw

@mixin mobilemd2($mobilem)
  @if $desktop-mode == '2'
    @media only screen and (min-width: 480px)
      width : 425px
  @if $desktop-mode == '3'
    @media only screen and (min-width: 480px)
      width : auto
      height: 100%
@mixin mobilemd8($mobilem)
  @if $desktop-mode == '2'
    @media only screen and (min-width: 480px)
      left: -3px
      top: 53px
      overflow: hidden
      height: 783px
@mixin mobilepf($mobilem)
  @if $desktop-mode == '2'
    @media only screen and (min-width: 480px)
      background-image: url('assets/images/phone.png')
      padding-top: 53px
      padding-left: 14px
      height: 883px
      background-size: cover

@mixin mobilepb($mobilem)
  @if $desktop-mode == '2'
    @media only screen and (min-width: 480px)
      -ms-overflow-style: none
      scrollbar-width: none
      width: 425px
      height: 783px
      overflow: auto

@mixin mobilepbw($mobilem)
  @if $desktop-mode == '2'
    @media only screen and (min-width: 480px)
      display: none

@mixin mobilemd9($mobilem)
  @if $desktop-mode == '2'
    @media only screen and (min-width: 480px)
      min-height: 800px

/* You can add global styles to this file, and also import other style files */
.w-100
  width:100%
.w-80
  width: 80%

.w-50
  width:50%

.w-100p
  width:100px

.d-block
  display: block!important

.m-auto
  margin: auto!important

.float-right
  float: right

.float-left
  float: left

.p-0
  padding: 0px!important

.pl-0
  padding-left: 0px!important

.pb-0
  padding-bottom: 0px!important

.p-10p
  padding: 10px!important

.mr-10p
  margin-right: 10px!important
.mb-0
  margin-bottom: 0px!important
.ml-0
  margin-left: 0px!important
.mt-0
  margin-top: 0px!important

.diagnostic
  position: fixed
  bottom: 50px
  overflow-x: hidden

@import '@angular/material/theming'
$primaryPalette: mat-palette($mat-indigo)
$accentPallette: mat-palette($mat-pink, A200, A100, A400)

.panel-android
  background-color: mat-color($primaryPalette)

.panel-ios
  background-color: white

.form-wait-bg
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: black
  opacity: 0.4
  @include mobilemd100($desktop-mode)
  @include mobilemd8($desktop-mode)

.form-wait
  position: absolute
  top: 200px
  margin: auto
  width: 100%
  @include mobilemd2($desktop-mode)

  mat-spinner
    margin: auto

$top_bar_h: calc(100% - 64px)
.form-list
  height: $top_bar_h
  overflow: auto

@import "../node_modules/angular-calendar/css/angular-calendar.css"
.cal-month-view .cal-day-cell.cal-today
  background-color: white!important
.cal-month-view .cal-cell-row .cal-cell
  outline: none
  border: none
  min-height: 100%
.cal-cell-row
  padding-top: 8px
  height: calc(100vw / 7)
  padding-bottom: 8px
  @include mobilemd($desktop-mode)

.cal-month-view .cal-cell-row .cal-cell:hover
  background-color: white!important
.cal-weekend .col-day
  color: mat-color($accentPallette)
.cal-out-month .col-day
  color: #bbb
.cal-today .col-day
  border: solid 2px mat-color($primaryPalette)
  border-radius: 14px
  width: 24px!important
  height: 24px!important
  span
    left: -1px!important
    top: 2px!important
.cal-selected.col-day
  background-color: mat-color($primaryPalette)
  color: white
  border-radius: 14px

.modal-popup
  position: absolute
  top: 0px
  left: 0px
  width: 100%
  height: 100%
  @include mobilemd100($desktop-mode)
  @include mobilemd8($desktop-mode)

  .bg
    position: absolute
    top: 0px
    left: 0px
    width: 100%
    height: 100%
    background-color: black
    opacity: 0.4

  .dlg-center
    display: flex
    -webkit-box-orient: vertical
    justify-content: center
    align-items: center
    text-align: center
    min-height: 100vh
    @include mobilemd9($desktop-mode)

  .dlg
    width: 80%
    position: absolute

    h2
      text-align: center

    button.mat-raised-button
      margin: 10px
      width: calc(50% - 20px)

.product-page .mat-expansion-panel-body
    padding: 0px

.product-page.mat-expansion-panel-spacing
    margin: 0px!important

.product-page .mat-expansion-panel-header
    height: 46px!important

@media (hover: none)
  .product-page .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover
    background: #d6d7d6!important

.light-login .mat-form-field-label
  color: white!important

.dark-login .mat-form-field-label
  color: black!important

.light-login .mat-form-field-appearance-legacy .mat-form-field-underline
  background-color: white!important

.dark-login .mat-form-field-appearance-legacy .mat-form-field-underline
  background-color: black!important

button.checkout-button
  width: 125px!important

button.checkout-button .mat-button-wrapper
  white-space: normal
  line-height: 25px
  display: block

.total-label
  span
    border-bottom: solid 0.5px #bbb

.splide__slide
  img
    width : 100vw
    height: auto
    @include mobilemd2($desktop-mode)

.splide__slide__container
  height: 100vh
  display: table-cell
  vertical-align: middle

.phone-frame
  @include mobilepf($desktop-mode)

.phone-body
  @include mobilepb($desktop-mode)

.phone-body::-webkit-scrollbar
  @include mobilepbw($desktop-mode)

.page-forum
  .mat-card-header-text
    width: 100%

.icon.attend
  color: green
.icon.not-attend
  color: red
.icon.draft
  color: yellow

@import 'swiper/scss'
@import 'swiper/scss/navigation'
@import 'swiper/scss/pagination'


.selfie-preview
  overflow: hidden
  height: 100vh
  width: 100vw
  video
    border-radius: 50%
    max-width: 320px
    border: solid 100vw black
    object-fit: cover
    top: calc(-100vw + 15vh)
    position: absolute
    left: calc(-50vw - 160px)

  video::-webkit-scrollbar
    display: none